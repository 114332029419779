// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import JSONData from "../../content/my-projects.json"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={`Projects`}>
      <SEO title="Projects" />

      {
        JSONData.projects.map(({title, excerpt, relativeLink, displayLink, role}) => {
          return (
            <div key={displayLink}>
              <h4 style={{marginBottom: 0}}>{title}</h4>
              <p style={{margin: 0, color: 'rgb(108 105 105)'}}>{excerpt}</p>
              <p style={{margin: 0, color: 'rgb(108 105 105)'}}>Role: <b>{role}</b></p>
              <p><a href={relativeLink}>{displayLink}</a></p>
            </div>
          )
        })
      }
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`